import { filterEmpty } from './props-util';
import { cloneVNode, isVNode } from 'vue';
import warning from './warning';
export function cloneElement(vnode, nodeProps = {}, override = true, mergeRef = false) {
    let ele = vnode;
    if (Array.isArray(vnode)) {
        ele = filterEmpty(vnode)[0];
    }
    if (!ele) {
        return null;
    }
    const node = cloneVNode(ele, nodeProps, mergeRef);
    // cloneVNode内部是合并属性，这里改成覆盖属性
    node.props = (override ? Object.assign(Object.assign({}, node.props), nodeProps) : node.props);
    warning(typeof node.props.class !== 'object', 'class must be string');
    return node;
}
export function cloneVNodes(vnodes, nodeProps = {}, override = true) {
    return vnodes.map(vnode => cloneElement(vnode, nodeProps, override));
}
export function deepCloneElement(vnode, nodeProps = {}, override = true, mergeRef = false) {
    if (Array.isArray(vnode)) {
        return vnode.map(item => deepCloneElement(item, nodeProps, override, mergeRef));
    }
    else {
        // 需要判断是否为vnode方可进行clone操作
        if (!isVNode(vnode)) {
            return vnode;
        }
        const cloned = cloneElement(vnode, nodeProps, override, mergeRef);
        if (Array.isArray(cloned.children)) {
            cloned.children = deepCloneElement(cloned.children);
        }
        return cloned;
    }
}
